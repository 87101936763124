.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* z-index: 2; */
}

.outerCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  scale: 1;
  border: 1px solid #d3d3d3;
  display: block;
}

.menuText {
  text-decoration: none;
  font-size: 10px;
  font-family: SofiaPro-Light;
  color: #fff;
  margin-right: -15px;
  z-index: 40;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.innerContainer {
  display: inline-flex;
  margin-left: -70px;
  rotate: 0;
  /* z-index: 2; */
}

.innerCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  align-self: center;
  z-index: 10;
  margin-left: -5px;
}

.close {
  width: 20px;
  height: 20px;
  background-image: url("../assets/icons/close.png");
  position: absolute;
  background-size: contain;
  right: 81px;
}

@media only screen and (max-width: 576px) {
  .close {
    display: none;
  }
  .outerCircle {
    width: 80px;
    height: 80px;
    border: 1px solid #d3d3d3;
  }
  .innerContainer {
    margin-left: -62px;
  }
  .menuText {
    margin-right: -10px;
  }
}
@media only screen and (max-width: 900px) {
  .outerCircle {
    width: 80px;
    height: 80px;
  }
  .innerContainer {
    margin-left: -62px;
  }
  .menuText {
    margin-right: -10px;
  }
}
