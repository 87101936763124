.footer {
    background-color: transparent;
    text-align: center;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 40px;
    height: 60px;
    width: 100%;
}

.footer_container {
    width: 1050px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0px 48px 0px 30px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/icons/footer-border.svg');
}

.footer_container span {
    padding-right: 3px;
    padding-left: 3px;
    color: #fff;
}

.footer_container > div {
    width: 33%;
}

.terms {
    text-align: right;
    font-size: 14;
    font-family: "SofiaPro-Regular";
}

.h3 {
    color: #ffffff;
    text-align: left;
    font-size: 14;
    font-family: "SofiaPro-Semibold";
}

.text {
    color: #ffffff;
}

.logo {
    width: 60px;
}