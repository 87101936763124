.container {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/background.png");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseVersionContainer {
  position: absolute;
  top: calc(50vh - 60px);
  left: 300px;
  z-index: 50;
  letter-spacing: 5px;
  height: 70px;
  /*padding-right: 40px;*/
  width: 15%;
  display: flex;
  align-items: center;
  background-image: url("../../assets/chooseSideBackground.png");
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.sideTxt {
  color: #fff;
  font-family: SofiaPro-Black;
  font-size: 120%;
  margin-left: 7px;
  font-weight: bold;
}

.topBackgroundStyle,
.bottomBackgroundStyle {
  width: 50%;
  height: 45%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.textTop {
  z-index: 400;
  color: #fff;
  font-family: SofiaPro-Black;
  font-size: 450%;
  margin-top: 150px;
}

.textBottom {
  z-index: 400;
  color: #fff;
  font-family: SofiaPro-Black;
  font-size: 450%;
  margin-bottom: 150px;
}

@media only screen and (max-width: 576px) {
  .container {
    background-image: none;
    height: 100vh;
    padding-top: 200px;
  }
  .chooseVersionContainer {
    top: calc(20vh - 30px);
    left: 23%;
    width: auto;
    letter-spacing: 4px;
    background-image: none;
  }
  .sideTxt {
    color: #404040;
    font-size: 15px;
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040;
    padding: 5px;
  }
  .topBackgroundStyle {
    background-size: cover;
    height: 40vh;
  }
  .bottomBackgroundStyle {
    background-size: cover;
    height: 40vh;
    background-color: "pink";
  }
  .textTop {
    font-size: 20px;
  }
  .textBottom {
    font-size: 20px;
  }
}
