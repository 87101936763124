.container {
  background-color: #000;
  width: 100%;
  position: relative;
  z-index: -20;
}

.mainImageContainer {
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 10;
  position: relative;
}

.emptySection {
  height: 20vh;
  background-color: #000;
  z-index: 2;
  position: relative;
}

.mainImage {
  height: 100vh;
  transform-origin: 50% 50%;
  background-image: url("../../assets/portfolio/PORTFOLIO.png");
  background-size: 101% 103vh;
  transform: scale(5.2);
  background-repeat: no-repeat;
}

.backgroundTextContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
}

.backgroundTitle {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #333;
  -webkit-font-smoothing: antialiased;
  font-family: "SophiaPro-Bold";
  text-align: center;
  margin-top: 10%;
}

.bottomSection {
  width: 100%;
  height: 360vh;
  background-color: #000;
  margin-top: 400px;
  z-index: 2;
  position: relative;
}

.bottomSectionContainer {
  width: 100%;
  height: 360vh;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 253vh;
  background-color: transparent;
  overflow: hidden;
  padding-top: 40px;
}

.projectImagesContainer {
  width: 50%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  margin-left: 25%;
}

.projectImage {
  width: 48%;
  object-fit: contain;
}

.stairsImage {
  width: 40%;
  height: 900px;
  margin-left: 70%;
  margin-top: -450px;
  object-fit: contain;
}

.globeImage {
  width: 20%;
  height: 300px;
  object-fit: contain;
  margin-top: -500px;
}

.titleConatiner {
  width: 40%;
  margin-left: 30%;
  margin-top: -200px;
  line-height: 0.2px;
}

.title {
  color: #fff;
  font-family: "SophiaPro-Light";
  text-transform: uppercase;
  font-size: 25px;
  text-align: center;
}

.shortDescription {
  color: #fff;
  font-family: "SophiaPro-Regular";
  font-size: 18px;
  text-align: center;
}

.firstDescriptionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 200px;
  align-items: center;
  justify-content: center;
}

.firstContentContainer {
  width: 30%;
  margin-left: 100px;
  max-width: 400px;
}

.title {
  color: #fff;
  font-family: SofiaPro-Bold;
  font-size: 14px;
}
.desc {
  color: #fff;
  font-family: SofiaPro-Light;
  font-size: 18px;
}

.fadedDesc {
  color: #fff;
  font-family: SofiaPro-Light;
  font-size: 16px;
  opacity: 0.5;
}

.borderContentContainer {
  background-image: url("../../assets/portfolio/border.png");
  width: 350px;
  background-size: 100% 100%;
  height: 80px;
  background-repeat: no-repeat;
  padding: 10px;
}

.bordeText {
  color: #fff;
  font-family: SofiaPro-Medium;
  text-size-adjust: 100%;
  font-size: 14px;
  margin-right: 15px;
}

.galaxy {
  width: 80%;
  object-fit: contain;
}

.globeImage2 {
  width: 10%;
  object-fit: contain;
  margin-left: -100px;
}

.rotatingPics {
  width: 40%;
  object-fit: contain;
  margin-left: 20%;
}

.appStoreIcon {
  width: 140px;
  object-fit: contain;
}

.secondDescriptionContainer {
  width: 100%;
  overflow: hidden;
  margin-top: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectInfoContainer {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #333;
  line-height: 0.1px;
  padding-bottom: 40px;
  width: 300px;
}

.projectinfoKeyword {
  color: #fff;
  flex: 50%;
  font-family: "SofiaPro-Light";
  text-align: center;
}

.additionalInfo {
  color: #fff;
  font-family: "SofiaPro-Light";
  opacity: 0.5;
  margin: 40px 0;
  font-size: 14px;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
}

.bottomImageContainer {
  background-image: url("../../assets/portfolio/portfolioFooter.png");
  background-size: cover;
  height: 50vh;
  width: 1005;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  line-height: 0.1px;
}

.bottomContainer {
  width: 100%;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}

.fadedDescContainer {
  width: 40%;
  line-height: 1;
  text-align: center;
}

.shareContainer {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.shareIcon {
  width: 120px;
  object-fit: contain;
}

.randomImagesContainer {
  width: 90vw;
  margin-top: 100px;
  height: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.randomImages {
  object-fit: cover;
  width: 10vw;
  height: 600px;
  background-color: #aaa;
  margin-right: 20px;
  transition: 0.5s ease;
}
