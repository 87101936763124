.container {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/background.png");
  background-size: 100vw 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.astrounautsImage {
  width: 80vw;
  height: 70vh;
  object-fit: contain;
  position: absolute;
  z-index: 20;
  align-self: center;
  top: 0;
}

.contentContainer {
  width: 100%;
  height: 80vh;
  background-image: url("../../assets/home/bg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.linkContainer {
  width: 200px;
  height: 200px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.linkText {
  color: #fff;
  font-family: SofiaPro-Light;
  font-size: 22px;
}

.linkImg {
  width: 30px;
  object-fit: contain;
}
