.NavBar {
  background: transparent;
  position: fixed;
  top: 0;
  width: calc(100% - 140px);
  padding: 40px 70px;
  z-index: 400;
}

.NavBarShow {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 70px;
  flex: 1;
  background-color: white;
  position: fixed;
  background-image: url("../assets/menuBackground.png");
  background-position: "center";
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  z-index: 400;
}

.NavBarHide {
  display: none;
}

.TopNav {
  width: 100%;
  flex-direction: row;
  height: 80px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-self: center;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.menuLinks {
  display: flex;
  flex-direction: row;
  font-family: "SofiaPro-Regular";
  font-size: 50px;
  align-items: center;
  height: 80px;
  color: "#000";
}

.menuNumbers {
  font-family:"SofiaPro-Light";
}

.addressInfo {
  background-image: url("../assets/adressInfoBg.png");
  background-size: 100% 80px;
  background-repeat: no-repeat;
  background-position: "center";
  padding: 10px;
  width: 26%;
  height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 65px;
  margin-top: 50px;
}

.address {
  font-size: 14;
  font-family: "SofiaPro-Black";
  margin: 0;
}

.addressText {
  font-size: 14;
  font-family: "SofiaPro-Regular";
  margin: 0;
}

.backContainer {
  bottom: 3%;
  left: 60px;
  background-color: transparent;
  width: 200px;
  align-items: center;
  display: flex;
  z-index: 70;
  text-decoration: none;
  position: absolute;
}

.backText{
  font-family: 'SofiaPro-Medium';
  font-size: 15px;
  color: #000; 
}

.menuLinksContainer {
  display: flex;
  flex-direction: column;
  margin-left: 14%;
  margin-top: 120px;
  
}

.linkItem {
  text-decoration: none; 
  color: #000;
  /*margin-bottom: 30px;*/
}

.hrLine {
  display: none;
  width: 100%;
}

@media only screen and (max-width: 576px){
  .NavBarShow {
    background-image: none;
  }
  .logo {
    width: 70px;
    height: 70px;
    margin-left: -30px;
  }
  .menuLinksContainer {
    margin-left: 0%;
    margin-top: 190px;
} 
  .menuLinks {
    font-size: 35px;
    align-items: left;
    height: 50px;
    margin-left: -30px;
  }
  .addressInfo {
   display: none;
  }
  .backContainer {
  bottom: 12%;
  margin-left: -30px;
  }
  .backText {
    font-size: 16px;
  }
  .menuNumbers {
    font-family:"SofiaPro-Light";
  }
  .linkItem {
    margin-bottom: 0;
  }
  .hrLine {
    display: inline;
    position: absolute;
    bottom: 10%;
    border-bottom: 1px solid #ebebeb; 
    margin-left: -70px;
  }
}