.socialContainer {
  position: absolute;
  bottom: 3%;
  height: 30px;
  background-color: transparent;
  display: flex;
  z-index: 7;
  /*width: calc(100% - 140px);*/
  margin: 0 auto;
  left: 0;
  right: 0;
}

.darkSocialContainer {
  position: absolute;
  bottom: 6%;
  height: 30px;
  background-color: transparent;
  display: flex;
  z-index: 7;
  width: calc(100% - 140px);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.socialContainer a,
.darkSocialContainer a {
  padding-right: 5px;
}

.socialIcon {
  width: 17px;
  height: 17px;
}

.darkSocialIcon {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 576px) {
  .socialContainer {
    bottom: 3%;
    width: 100px;
    margin-right: 60px;
  }
  .darkSocialContainer {
    bottom: 4%;
    width: 150px;
    position: fixed;
    border-top: 1px solid #404040;
    padding: 30px 500px 0px 150px;
  }
}
